@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  background-color: #272727; /* Replace with your desired color */
  margin: 0; /* Remove default margin */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Pulsating circle */
.pulsating-circle {
  position: absolute;
  right: 9px; /* Position the circle closer to the button */
  top: 15px; /* Position it at the top right corner */
  width: 10px; /* Keep it tiny */
  height: 10px; /* Keep it tiny */

  &:before {
      content: '';
      position: relative;
      display: block;
      width: 300%;
      height: 300%;
      box-sizing: border-box;
      margin-left: -100%;
      margin-top: -100%;
      border-radius: 50%; /* Ensure the circle is round */
      background-color: red; /* Change to red */
      animation: pulse-ring 2.5s cubic-bezier(0.215, 0.61, 0.355, 1) infinite; /* Increased duration */
  }

  &:after {
      content: '';
      position: absolute;
      left: 0; 
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgb(255, 0, 0);
      border-radius: 50%; /* Ensure the inner circle is round */
      box-shadow: 0 0 8px rgba(0,0,0,.3);
      animation: pulse-dot 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite; /* Increased duration */
  }
}

@keyframes pulse-ring {
  0% {
      transform: scale(.33);
  }
  80%, 100% {
      opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
      transform: scale(.8);
  }
  50% {
      transform: scale(1);
  }
  100% {
      transform: scale(.8);
  }
}

/* flip card container and card */
.flip-card {
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 15px;
}

.flip-card-front {
  background-image: url('/src/assets/images/bitcoinart.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-front .countdown {
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 2rem; /* Larger font size */
  font-weight: bold;
  color: #fff; /* White color for text */
}

.flip-card-back {
  background-color: #2d3748;
  color: white;
  transform: rotateY(180deg);
  border-radius: 15px;
  overflow: hidden;
}


